// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-taller-js": () => import("./../src/templates/taller.js" /* webpackChunkName: "component---src-templates-taller-js" */),
  "component---src-templates-intranet-js": () => import("./../src/templates/intranet.js" /* webpackChunkName: "component---src-templates-intranet-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-inscripcions-js": () => import("./../src/pages/inscripcions.js" /* webpackChunkName: "component---src-pages-inscripcions-js" */),
  "component---src-templates-post-js": () => import("./../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contacte-js": () => import("./../src/pages/contacte.js" /* webpackChunkName: "component---src-pages-contacte-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projecte-js": () => import("./../src/pages/projecte.js" /* webpackChunkName: "component---src-pages-projecte-js" */),
  "component---src-pages-registre-js": () => import("./../src/pages/registre.js" /* webpackChunkName: "component---src-pages-registre-js" */),
  "component---src-pages-tallers-js": () => import("./../src/pages/tallers.js" /* webpackChunkName: "component---src-pages-tallers-js" */),
  "component---src-pages-treballa-amb-nosaltres-js": () => import("./../src/pages/treballa-amb-nosaltres.js" /* webpackChunkName: "component---src-pages-treballa-amb-nosaltres-js" */)
}

